import { CrudFilter } from "../../plugins/Cruxtify/CrudFilter";

export function extendWithDeleted(defs: any[] = []) {
  const baseCollectionLayout =
    defs.find((def) => ["table", "list", "default"].includes(def.id)) ||
    (defs.length > 0 ? defs[0] : {});

  // If the deleted layout is already defined, don't add it again
  if (defs.find((def) => def.id === "deleted")) return defs;

  const deletedLayout = {
    ...baseCollectionLayout,
    id: "deleted",
    columnFields: [
      "deleted_at",
      ...(baseCollectionLayout.columnFields
        ? baseCollectionLayout.columnFields
        : []),
    ],
    filters: [
      ...(baseCollectionLayout.filters ? baseCollectionLayout.filters : []),
      new CrudFilter({
        name: "onlyDeleted",
        query: {
          _only_deleted: 1,
        },
      }),
    ],
    defaultSort: [
      {
        field: "deleted_at",
        order: "desc",
      },
    ],
    itemActionComponents: ["deleted-actions"],
    disableRowClick: true,
  };

  return [...defs, deletedLayout];
}
