import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";
import {
  RelationshipProperty,
  StringProperty
} from "../plugins/Cruxtify/property-types";

import {
  UserPermission,
  UserPermissions,
  UserRole
} from "../plugins/Cruxtify/UserPermission";
import { RelationshipField } from "../plugins/Cruxtify/field-types";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

export class ContractTypeCountry extends CrudModel {
  protected static _typeLabel = "Contract Type Country";
  protected static asProperty = "contract_type_country";
  public static api = {
    path: "contract-type-countries"
    //fullCache: true
  };

  protected userIdField = [];
  protected static routeBase = "contract-type-countries";

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: StringProperty,
      opts: { name: "country_value", label: "Country Name on Contract" }
    },
    {
      type: StringProperty,
      opts: {
        name: "api_id",
        label: "API ID",
        description:
          "The ID of the Signaturit template to use, beginning with a #"
      }
    },
    {
      type: StringProperty,
      opts: { name: "reg_type", label: "Reg. Type on Contract" }
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "contract_type",
        label: "Contract Type",
        relatedModel: "ContractType"
      }
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "country",
        label: "Country",
        relatedModel: "Country"
      }
    }
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,

    {
      type: StringField,
      opts: { property: "country_value", isPrimaryLabelField: 1 }
    },
    { type: StringField, opts: { property: "api_id" } },

    { type: StringField, opts: { property: "reg_type" } },

    // relationships
    { type: RelationshipField, opts: { property: "country" } },
    {
      type: RelationshipField,
      opts: { property: "contract_type" }
    }
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "default",
      label: "Default",
      fields: [
        "country",
        "country_value",
        "reg_type",
        "api_id",
        "contract_type"
      ]
    }
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      inlineEdit: true,
      columnFields: ["country", "country_value", "reg_type", "api_id"],
      filterFields: [],
      defaultSort: [
        {
          field: "country_value",
          order: "asc"
        }
        // {
        //   field: "step_num",
        //   order: "asc"
        // }
      ]
    }
  ];
}
