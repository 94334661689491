import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  RelationshipProperty,
  StringProperty
} from "../plugins/Cruxtify/property-types";

import {
  UserPermission,
  UserPermissions,
  UserRole
} from "../plugins/Cruxtify/UserPermission";
import { DateTimeField, StringField } from "../plugins/Cruxtify/field-types";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

export class ContractEvent extends CrudModel {
  protected static _typeLabel = "Contract Event";
  protected static asProperty = "contract_event";

  public static api = {
    path: "contract-events"
  };

  protected userIdField = [];
  protected static routeBase = "contract-events";

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit
    },
    UserPermission.Hidden
  );

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: StringProperty,
      opts: { name: "message", label: "Message" }
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "contract",
        label: "Contract",
        relatedModel: "Contract"
      }
    }
  ];

  protected static fieldDefinitions = [
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
        label: "Date"
      }
    },

    {
      type: StringField,
      opts: { property: "message", isPrimaryLabelField: 1 }
    }
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      modalOptions: {
        readmodeDefault: false
      },
      useModal: true,
      columnFields: [],
      filterFields: []
    }
  ];
}
