import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";
import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

import {
  LongTextField,
  RelationshipField,
  DateTimeField,
} from "../plugins/Cruxtify/field-types";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

export class Note extends CrudModel {
  protected static _typeLabel = "Note";
  protected static asProperty = "note";
  public static api = {
    path: "notes",
  };

  protected userIdField = ["author"];
  protected static routeBase = "notes";

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
    },
    UserPermission.Hidden
  );

  protected static propertyDefinitions = [
    idAndTimestamps.properties[0],
    idAndTimestamps.properties[3],

    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        displayRelative: false,
        userPermissions: UserPermission.Read,
        isRemotelyComputed: true,
        sortable: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        displayRelative: false,
        userPermissions: UserPermission.Read,
        isRemotelyComputed: true,
        sortable: true,
      },
    },

    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },

    { type: StringProperty, opts: { name: "subject", label: "Subject" } },
    { type: StringProperty, opts: { name: "body", label: "Body" } },

    // relationships
    {
      type: RelationshipProperty,
      opts: {
        name: "author",
        label: "Author",
        relatedModel: "User",
        isRemotelyComputed: true,
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "contract",
        label: "Contract",
        relatedModel: "Contract",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "event",
        label: "Event",
        relatedModel: "Event",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "studio",
        label: "Studio",
        relatedModel: "Studio",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "trainer",
        label: "Trainer",
        relatedModel: "Trainer",
      },
    },
  ];

  protected static fieldDefinitions = [
    idAndTimestamps.fields[0],
    idAndTimestamps.fields[3],
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
        displayRelative: false,
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "updated_at",
        displayRelative: false,
      },
    },

    {
      type: StringField,
      opts: { property: "subject", isPrimaryLabelField: 1, cols: 12 },
    },
    { type: LongTextField, opts: { property: "body", cols: 12 } },

    // relationships
    { type: RelationshipField, opts: { property: "author" } },
    { type: RelationshipField, opts: { property: "contract" } },
    { type: RelationshipField, opts: { property: "event" } },
    { type: RelationshipField, opts: { property: "studio" } },
    { type: RelationshipField, opts: { property: "trainer" } },
  ];

  protected static modelLayoutDefinitions = [
    {
      type: "FieldSetGridLayout",
      opts: {
        id: "default",
        fieldSets: [
          {
            id: "note",
            fields: ["subject", "body"],
          },
          // {
          //   id: "rel",
          //   fields: ["contract", "event", "studio", "trainer"],
          // },
          {
            id: "meta",
            fields: ["author", "created_at", "updated_at"],
          },
        ],
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      inlineEdit: false,
      useModal: true,
      columnFields: ["subject", "body", "author"],
      filterFields: [],
      searchLabel: "Search",
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
        // {
        //   field: "step_num",
        //   order: "asc"
        // }
      ],
    },
  ];
}
